var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"patientBox"},[_c('h2',{staticClass:"patient-title"},[_vm._v(_vm._s(_vm.$t('side.patient.title')))]),(_vm.targetInfo.patientSn)?_c('div',{},[_c('ul',{staticClass:"patientList line"},[_vm._l((_vm.vaildTargetHeader),function(item,i){return _c('li',{key:i,staticClass:"patient-input"},[_c('div',{staticClass:"input-title"},[_vm._v(_vm._s(item.name))]),(item.value === 'sex')?_c('div',{staticClass:"input-value"},[_vm._v(_vm._s(_vm.translateSex(_vm.targetInfo[item.value])))]):(item.value === 'age')?_c('div',{staticClass:"input-value"},[_vm._v(_vm._s(_vm.translateAge(_vm.targetInfo[item.value])))]):_c('div',{staticClass:"input-value"},[_vm._v(_vm._s(_vm.targetInfo[item.value]))])])}),(_vm.currentRouteName === 'step1' && !_vm.targetInfo.isDicom)?_c('li',{staticClass:"patient-input"},[_c('BtnCompo',{attrs:{"width":'100%',"height":'34px',"txtBtn":_vm.buttonText,"fontSize":'14px',"fontWeight":'500',"borderRadius":'4px',"marginTop":'10px',"onClick":() => {
                        _vm.viewPatientList()
                }}})],1):_vm._e()],2)]):_c('div',[_c('ul',{staticClass:"patientList line checkFirst"},[_c('li',{staticClass:"patient-input"},[_c('div',{staticClass:"input-title"},[_vm._v(_vm._s(_vm.$t('side.patient.init.text')))])]),_c('li',{staticClass:"patient-input"},[_c('BtnCompo',{attrs:{"width":'100%',"height":'34px',"txtBtn":_vm.initButtonText,"fontSize":'14px',"fontWeight":'500',"borderRadius":'4px',"marginTop":'10px',"onClick":() => {
                        _vm.viewPatientList()
                }}})],1)])]),_c('v-dialog',{attrs:{"width":"320"},model:{value:(_vm.openPopup),callback:function ($$v) {_vm.openPopup=$$v},expression:"openPopup"}},[_c('DefaultPopup',{attrs:{"popupText":_vm.msg,"secondTxt":_vm.popupButton,"firstBtn":false,"onClick":() => { 
                _vm.openPopup = false;
                _vm.isNewPatient = true;
                _vm.formValues.patientSn = _vm.patientSn
             }},on:{"closePopup":function($event){_vm.openPopup = false}}})],1),_c('v-dialog',{attrs:{"width":"720"},model:{value:(_vm.patientPopupVisible),callback:function ($$v) {_vm.patientPopupVisible=$$v},expression:"patientPopupVisible"}},[_c('PatientListPopup',{on:{"closePopup":_vm.closePopup,"selectedTargetInfo":_vm.setTargetInfo}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }