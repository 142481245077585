<template>
    <div class="patientBox">
        <h2 class="patient-title">{{ $t('side.patient.title') }}</h2>
        <!-- 등록된 환자 정보가 있는 경우 -->
        <div class="" v-if="targetInfo.patientSn">
            <ul class="patientList line">
                <li class="patient-input" v-for="(item, i) in vaildTargetHeader" :key="i">
                    <div class="input-title">{{item.name}}</div>
                    <div v-if="item.value === 'sex'" class="input-value">{{ translateSex(targetInfo[item.value]) }}</div>
                    <div v-else-if="item.value === 'age'" class="input-value">{{ translateAge(targetInfo[item.value]) }}</div>
                    <div v-else class="input-value">{{ targetInfo[item.value] }}</div>
                </li>
                <li class="patient-input" v-if="currentRouteName === 'step1' && !targetInfo.isDicom">
                    <BtnCompo
                        :width="'100%'"
                        :height="'34px'"
                        :txtBtn="buttonText"
                        :fontSize="'14px'"
                        :fontWeight="'500'"
                        :borderRadius="'4px'"
                        :marginTop="'10px'"
                        :onClick="() => {
                            viewPatientList()
                    }" />
                </li>
            </ul>
        </div>
        <!-- 초기화 화면 && 이미지(jpg, jpeg, png) 파일이 등록된 경우 -->
        <div v-else>
            <ul class="patientList line checkFirst">
                <li class="patient-input">
                    <div class="input-title">{{ $t('side.patient.init.text') }}</div>
                </li>
                <li class="patient-input">
                    <BtnCompo
                        :width="'100%'"
                        :height="'34px'"
                        :txtBtn="initButtonText"
                        :fontSize="'14px'"
                        :fontWeight="'500'"
                        :borderRadius="'4px'"
                        :marginTop="'10px'"
                        :onClick="() => {
                            viewPatientList()
                    }" />
                </li>
            </ul>
        </div>
        <v-dialog v-model="openPopup" width="320">
            <DefaultPopup
                :popupText="msg"
                :secondTxt="popupButton"
                :firstBtn="false"
                :onClick="() => { 
                    openPopup = false;
                    isNewPatient = true;
                    formValues.patientSn = patientSn
                 }"
                @closePopup="openPopup = false"
            />
        </v-dialog>
        <v-dialog v-model="patientPopupVisible" width="720">
            <PatientListPopup  @closePopup="closePopup" @selectedTargetInfo="setTargetInfo"/>
        </v-dialog>
    </div>
</template>

<script>
import EventBus from '../plugins/EventBus'
import { rules } from '../mixin/rules'

export default {
    components: {
        PatientListPopup: () => import('../popup/patientListPopup.vue'),
    },
    mixins: [rules],
    props: {
    },
    data() {
        return {
            formValues: {},
            patientSn: null,
            xrayMap: {},
            openPopup: false,
            msg: "",
            initialValue: '',
            targetInfo: JSON.parse(localStorage.getItem("targetInfo"))?? {},
            patientPopupVisible: false
        };
    },
    watch: {
    },
    created() {},
    mounted() {},
    destroyed() {},
    methods: {
        viewPatientList(){
            this.patientPopupVisible = true
        },
        closePopup(){
            this.patientPopupVisible = false
        },
        setTargetInfo(value){
            this.targetInfo = value;
            if(localStorage.getItem('selectedTargetInfo')) {
                localStorage.setItem('targetInfo', localStorage.getItem('selectedTargetInfo'))
                localStorage.removeItem('selectedTargetInfo')
            }
            EventBus.emit('change_target');
        },
    },
    computed:{
        currentRouteName() {
            return this.$route.name;
        },
        buttonText(){
            return this.$t('side.patient.change');
        },
        initButtonText(){
            return this.$t('side.patient.init.button');
        },
        vaildTargetHeader() {
            return [
                {name: this.$t('common.patient.sn'), value: 'patientSn'},
                {name: this.$t('common.patient.name'), value: 'name'},
                {name: this.$t('common.patient.birth'), value: 'birth'},
                {name: this.$t('common.patient.age'), value: 'age'},
                {name: this.$t('common.patient.sex'), value: 'sex'}
            ];
        },
        popupButton(){
            return this.$t('common.button.ok')
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.input-title{width: calc(100% - 180px)}
.checkFirst{
    .input-title{width: 100%; text-align: center;}
    .button-wrap{position: static; padding: 15px 0;}
    &.input-wrap{
        display: flex; justify-content: space-between; align-items: center; gap: 5px;
        .input-value{
            width: calc(180px - 30%);
            .input{width: 100%; padding: 0 5px; }
        }
    }
}

.registForm{
    .input-value{width: 180px !important;}
}

.input{width: 100%; padding: 0 10px; }

.inpSet{display:inline-flex; align-items:center; gap:4px; color: #fff !important; width: 180px;}
.inpSet .srh{position:relative; display:inline-flex; flex-direction:row; flex-wrap:nowrap; gap:0; justify-content:space-between; background:#fff; border:1px solid #ccc; border-radius:var(--border); overflow:hidden; min-width:200px; height:var(--inputH);}
.inpSet .srh input{ padding:4px 0 4px 16px ; font-size: var(--fz-body); color:#fff; height:100%; width:calc(100% - 50px); border:none; outline:none;}
.inpSet .srhBtn{display:inline-flex; align-items:center; justify-content:center; width:var(--inputH); height:var(--inputH); color:#fff; background:var(--color-p20);}
.inpSet .inp{position:relative; display:inline-flex; align-items:center; background:#fff; border:1px solid #ccc; border-radius:var(--border); overflow:hidden; min-width:200px; height:var(--inputH);}
.inpSet .inp input{padding:4px 16px; font-size: var(--fz-body); color:#fff; height:100%; width:100%; border:none; outline:none;}

::v-deep .v-input--selection-controls{margin: 0;}
::v-deep .v-label{color: #fff;}

</style>