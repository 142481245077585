export const rules = {
    methods: {
        passwordRules(str) { // 비밀번호 체크
            const text = str !== undefined ? str.trim() : undefined
            if (text === undefined) {
                return [];
            }
            const errors = [];

            if (text.length < 8) {
                errors.push(this.$t('rules.checkPw.digit'));
            }

            const hasNumber = /\d/.test(text);
            const hasLetter = /[a-zA-Z]/.test(text);
            const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(text);

            if (!(hasNumber && hasLetter && hasSpecialChar)) {
                errors.push(this.$t('rules.checkPw.valid'));
            }

            return errors;
        },
        confirmPasswordRules(str1, str2) {
            const text1 = str1 !== undefined ? str1.trim() : undefined
            const text2 = str2 !== undefined ? str2.trim() : undefined
            if (text1 === undefined) {
                return [];
            }
            return [
                value => !!value || this.$t('rules.confirmPw.new'),
                value => !(value && text1 !== text2) || this.$t('rules.confirmPw.confirm'),
            ]
        },
        notNullRules(str) { // 필수입력
            const text = str.trim()
            return [
                value => !!value || this.$t('rules.notNull',{text:text})
            ]
        },
        userIdRules(str) { // 한글 입력 제한
            const text = str
            return [
                value => !!value || this.$t('rules.userId.notNull',{text:text}),
                value => !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || this.$t('rules.userId.valid',{text:text})
            ]
        },
        onlyDecimalRules(str) {
            const text = str
            return [
                value => !/^[-+]?[0-9]*\.?[0-9]*$/.test(value) || this.$t('rules.decimal',{text:text})
            ]
        },
        numericRule(str) {
            const text = str.trim()
            return [
                value => !!value || this.$t('rules.notNull',{text:text}),
                value => /^\d+$/.test(value) || this.$t('rules.numeric',{text:text})
            ]
        },
        none() {
            return []
        }
    }
}

export default { rules };